import gql from 'graphql-tag'


export const CREATE_CONTENT = (templateType) => gql`mutation CREATE_CONTENT ($paperType: String!, $paperId: Int!, $input: ${templateType}ContentInput!) {
  createContent: create${templateType}Content (paperType: $paperType, paperId: $paperId, input: $input) {
    id order
    title content
    imageContent tableContent
    isPageBreak
  }
}`

export const UPDATE_CONTENT = (templateType) => gql`mutation UPDATE_CONTENT ($paperType: String!, $contentId: Int!, $input: ${templateType}ContentInput!) {
  updateContent: update${templateType}Content (paperType: $paperType, contentId: $contentId, input: $input) {
    id order
    title content
    imageContent tableContent
    isPageBreak
  }
}`

export const DESTROY_CONTENT = (templateType) => gql`mutation DESTROY_CONTENT ($paperType: String!, $contentId: Int!) {
  destroyContent: destroy${templateType}Content (paperType: $paperType, contentId: $contentId) {id}
}`

export const MOVE_CONTENT = (templateType) => gql`mutation MOVE_CONTENT ($paperType: String!, $contentId: Int!, $step: Int!) {
  moveContent: move${templateType}Content (paperType: $paperType, contentId: $contentId, step: $step) {id}
}`
